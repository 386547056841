import { useState } from 'react'
import Header from './components/Header'
import Profile from './components/Profile'
import Experience from './components/Experience'
import Skills from './components/Skills'
import Education from './components/Education'
import Projects from './components/Projects'

function App() {
  return (
    <div className="app">
      <Header />
      <main className="main-content">
        <Profile />
        <Skills />
        <Experience />
        <Projects />
        <Education />
      </main>
    </div>
  )
}

export default App 