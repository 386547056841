import '../styles/Education.css'

function Education() {
  return (
    <section className="education">
      <h3>EDUCATION</h3>
      <div className="degree">
        <h4>University of Maryland Global Campus</h4>
        <p>Bachelor of Science in Business Administration</p>
      </div>
    </section>
  )
}

export default Education 