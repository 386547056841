import '../styles/Experience.css'

function Experience() {
  return (
    <section className="experience">
      <h3>WORK EXPERIENCE</h3>
      
      <div className="job">
        <h4>Chief Technology Officer</h4>
        <div className="job-info">
          <span>BuyItaly Mortgages LLC | St. Petersburg FL</span>
          <span>Dec 2021 – Present | 40 Hrs/Week</span>
        </div>
        <ul>
          <li>Lead IT modernization initiatives, aligning infrastructure with long-term strategic goals.</li>
          <li>Oversee end-to-end project management for enterprise-level technology implementations.</li>
          <li>Collaborate with senior leadership and stakeholders to design IT architecture and modernization plans.</li>
          <li>Develop and implement IT policies and frameworks to enhance system efficiency and compliance.</li>
        </ul>
      </div>

      <div className="job">
        <h4>Software Developer</h4>
        <div className="job-info">
          <span>Kaai Tech LLC | El Paso, TX</span>
          <span>Jan 2018 – Present | 40 Hrs/Week</span>
        </div>
        <ul>
          <li>Deliver comprehensive IT solutions, including web, application development, and implementing security systems, and policies for small businesses.</li>
          <li>Develop scalable systems using MERN, MEAN, and LAMP stacks, with deployments on AWS and Digital Ocean.</li>
          <li>Create technology roadmaps and modernize IT systems to enhance client operations.</li>
          <li>Draft IT proposals and negotiate project scope and budget with clients and stakeholders.</li>
        </ul>
      </div>

      <div className="linkedin-cta">
        <a 
          href="https://linkedin.com/in/korykaai" 
          target="_blank" 
          rel="noopener noreferrer"
          className="linkedin-button"
        >
          View Full Work History on LinkedIn
        </a>
      </div>
    </section>
  )
}

export default Experience 