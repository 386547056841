import '../styles/Skills.css'

function Skills() {
  const skills = [
    "IT Project Management (Agile, Waterfall)",
    "Systems Modernization and Optimization",
    "Telecommunications and Network Planning",
    "Policy and Framework Development",
    "Stakeholder Engagement and Negotiation",
    "Technical Leadership and Strategic Planning",
    "Full-Stack Development: React, Django, AngularJS, Spring, Python, SQL, Java"
  ]

  return (
    <section className="skills">
      <h3>CORE COMPETENCIES</h3>
      <ul className="skills-list">
        {skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
    </section>
  )
}

export default Skills 