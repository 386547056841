import '../styles/Projects.css'

function Projects() {
  return (
    <section className="projects">
      <h3>PROJECT EXPERIENCE</h3>
      
      <div className="project">
        <h4>Enterprise IT Modernization Platform</h4>
        <ul>
          <li>Architected and developed a comprehensive IT management system using React, Django, and PostgreSQL, enabling organizations to track and modernize their IT infrastructure.</li>
          <li>Implemented real-time monitoring dashboards and automated reporting systems that reduced system downtime by 40%.</li>
          <li>Integrated with AWS services (EC2, S3, Lambda) for scalable cloud deployment and automated resource management.</li>
          <li>Built secure API endpoints with JWT authentication and role-based access control for different stakeholder levels.</li>
        </ul>
      </div>

      <div className="project">
        <h4>Veteran One Up</h4>
        <ul>
          <li>Developed a resource platform for veterans to access financial tools and community support.</li>
          <li>Integrated financial calculators and planning tools into the platform, enhancing user accessibility.</li>
          <li>Implemented secure user authentication and data encryption to protect sensitive veteran information.</li>
        </ul>
      </div>
    </section>
  )
}

export default Projects 