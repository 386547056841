import '../styles/Header.css'
import profilePic from '../assets/pic.png'
import { useState } from 'react'
import ReCAPTCHA from "react-google-recaptcha"
import emailjs from 'emailjs-com'
// Temporarily comment out until image is created
// import contactInfo from '../assets/contact-info.png'

const RECAPTCHA_SITE_KEY = '6Lc0-MIqAAAAAPEzfrqO-h0eJNaA5X-LU4FMK0Aa'

function Header() {
  const [showContactForm, setShowContactForm] = useState(false)
  const [captchaToken, setCaptchaToken] = useState('')
  const [formStatus, setFormStatus] = useState('')
  
  // Encode the contact information
  const email = 'email@korykaai.com'
  const phone = '+39 3518516573'
  
  const handleSubmit = async (event) => {
    event.preventDefault()
    
    if (!captchaToken) {
      setFormStatus('Please complete the captcha')
      return
    }

    try {
      const formData = new FormData(event.target)
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.get('name'),
          email: formData.get('email'),
          message: formData.get('message'),
          captchaToken
        })
      })

      if (response.ok) {
        setFormStatus('Message sent successfully!')
        event.target.reset()
        setTimeout(() => setShowContactForm(false), 3000)
      } else {
        setFormStatus('Failed to send message. Please try again.')
      }
    } catch (error) {
      setFormStatus('An error occurred. Please try again.')
    }
  }

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token)
  }

  return (
    <header className="header">
      <div className="header-content">
        <div className="profile-header">
          <div className="name-and-image">
            <h1>Kory Kaai</h1>
            <div className="profile-image">
              <img src={profilePic} alt="Kory Kaai" />
            </div>
          </div>
        </div>
        <h2>SOFTWARE DEVELOPER | TECHNOLOGY LEADER | STRATEGIC PLANNER</h2>
        <div className="contact-info">
          <span>📍 Vicenza, Italy</span>
          <button 
            onClick={() => setShowContactForm(true)} 
            className="contact-button"
          >
            Contact Me
          </button>
          
          {showContactForm && (
            <div className="contact-form-modal">
              <div className="modal-content">
                <button 
                  className="close-button"
                  onClick={() => setShowContactForm(false)}
                >
                  ×
                </button>
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input 
                      type="text" 
                      name="name"
                      placeholder="Your Name" 
                      required 
                    />
                  </div>
                  <div className="form-group">
                    <input 
                      type="email" 
                      name="email"
                      placeholder="Your Email" 
                      required 
                    />
                  </div>
                  <div className="form-group">
                    <textarea 
                      name="message"
                      placeholder="Message" 
                      required 
                    />
                  </div>
                  <div className="form-group">
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={handleCaptchaChange}
                    />
                  </div>
                  {formStatus && (
                    <div className={`form-status ${formStatus.includes('success') ? 'success' : 'error'}`}>
                      {formStatus}
                    </div>
                  )}
                  <button type="submit" className="submit-button">
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
        <div className="social-links">
          <a href="https://github.com/kory-grinds" target="_blank" rel="noopener noreferrer">GitHub</a>
          <a href="https://linkedin.com/in/korykaai" target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </div>
      </div>
    </header>
  )
}

export default Header 