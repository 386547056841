import '../styles/Profile.css'

function Profile() {
  return (
    <section className="profile">
      <h3>PROFESSIONAL SUMMARY</h3>
      <p>
        Results-driven Chief Technology Officer (CTO) and military veteran with 16+ years of
        experience in leadership, IT project management, and systems modernization. Proven
        expertise in aligning technology strategies with organizational goals, planning long-term IT
        initiatives, and implementing scalable solutions. Adept at developing policies, managing
        cross-functional teams, and negotiating with key stakeholders. Highly skilled in software
        development, system optimization, and network modernization.
      </p>
    </section>
  )
}

export default Profile 